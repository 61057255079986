
import { useFetchDetail, update, del } from '@/api/entity/course'
import { useRoute } from 'vue-router'
import { Dialog, Notify, Toast } from 'vant'
import CourseForm from '../Form.vue'
import { goBack } from '@/states/layout'

export default {
  components: { CourseForm },
  setup: () => {
    const { courseId } = useRoute().params
    const { courseDetail } = useFetchDetail({
      courseId: Number(courseId)
    })
    return {
      courseDetail,
      doSave: () => {
        update(courseDetail.value).then(() => {
          Toast.success('保存成功')
        })
      },
      askDel: () => {
        Dialog.confirm({
          title: '确认',
          message: '确认删除课程吗？删除后不可恢复！'
        }).then(() => {
          del(Number(courseId)).then(() => {
            Notify({
              type: 'success',
              message: '课程删除成功！'
            })
            goBack()
          })
        })
      }
    }
  }
}
